import {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Button from '@amzn/awsui-components-react/polaris/button';
import Input from "@amzn/awsui-components-react/polaris/input";
import * as React from 'react';
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";
import {TextFilter} from "@amzn/awsui-components-react";
import Badge from "@amzn/awsui-components-react/polaris/badge";

interface Option{
    label: string;
    value: string;
}

export default class FormPage extends React.Component {
    pageNumber = null;

    filteringText = '';
    state = {
        selectedOptions:[],
        value:'',
        setValue: [],
        link:"#/table",
        options: [
            {
                label: "Alexa-Photos-Autocuts",
                value: "0"
            },
            {
                label: "alexa-behaviors-autocuts",
                value: "1"
            },
            {
                label: "alexa-comms-pathfinder",
                value: "2"
            },
            {
                label: "MMContentService-Autocuts",
                value: "3"
            },
            {
                label: "PersonIDService-Autocuts",
                value: "4"
            },
            {
                label: "AlexaSpeakerSpeechlet-Autocuts",
                value: "5"
            }]
        
    };

    setValue = event => {
           this.setState({
          value: event.detail.value,
       });
    };
    buttonClicked = event =>{
        if (this.state.value) {
            this.setState({
                link: "#/ticket/"+this.state.value,
            });
        } else if (this.state.selectedOptions) {
            const selectedOptions= this.state.selectedOptions as Option[] ;
            const selectedResolverGroups = JSON.stringify(selectedOptions.map(item=>item.label));
            this.setState({
                link: "#/table/"+selectedResolverGroups,
            });
        }
    }

    handleChange = event => {
        this.setState({
            selectedOptions: event.detail.selectedOptions,
            setValue: event.detail.setValue,
        });
    };
    onSubmit = event => {
        if (this.state.value) {
            return <Badge color="green">Completed</Badge>
        } else if (this.state.selectedOptions) {
            return <Badge color="red">Cert not bound</Badge>
        }
    }
    render() {
        const {options, selectedOptions} = this.state;
        //@ts-ignore
        return (
            <Box padding={{top: 'xxl', horizontal: 's', bottom: 'l'}}>
                <Grid
                    gridDefinition={[
                        {colspan: {xl: 6, l: 6, s: 6, xxs: 8}, offset: {s: 3, xxs: 1}}
                    ]}
                >
                    <div>
                        <h1 className="required">Choose Resolver Group or Provide TicketID</h1>
                        <br>
                        </br>
                        <div>
                            <Form>
                                <FormField
                                    label={<h2 className="required">Resolver Group</h2>}>
                                    <Multiselect
                                        filteringType="manual"
                                        filteringPlaceholder="Find security group"
                                        filteringAriaLabel="Filtering aria label"
                                        placeholder="Choose a Resolver group"
                                        loadingText="Loading Resolver groups"
                                        errorText="Error fetching results."
                                        recoveryText="Retry"
                                        finishedText={this.filteringText ? `End of "${this.filteringText}" results` : 'End of all results'}
                                        empty="No security groups found"
                                        options={options}
                                        selectedOptions={selectedOptions}
                                        onChange={this.handleChange}
                                        keepOpen={false}
                                    />
                                </FormField>
                                <span className="btn-span float-center"></span>
            <br></br>


                            </Form>
                            <h2>OR</h2>
                        </div>

                        <br></br>
                        <div>
                            <Form>
                                <FormField
                                    label={<h2 className="required">Ticket Details</h2>}>
                                    <Input 
                                    onChange={this.setValue}
                                    value={this.state.value}
                                    placeholder="Enter Ticket ID"
                                    />
                                </FormField>
                                <span className="btn-span float-center">
            <br></br>
        <Button className="btn" id="certDetails"
            href={this.state.link}
                variant="primary" onClick={this.buttonClicked}
        >Submit Request</Button></span>
                            </Form>
                        </div>
                    </div>

                </Grid>
            </Box>
        );
    }
}
