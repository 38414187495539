import { Route, Switch } from 'react-router-dom';
import Homepage from './home';
import CommonHeader from './CommonHeader';
import FormPage from './form/FormPage';
import PetsTable from './table/Table';
import TicketTable from "./table/TicketTable";

export default function App() {

  return (
    <div>
      <CommonHeader />
      <Switch>
        <Route path="/form/:mode/:id" component={FormPage} />
        <Route path="/table/:resolverGroup" component={PetsTable} />
          <Route path="/ticket/:ticketId" component={TicketTable} />
        <Route exact path="/" component={Homepage} />
      </Switch>
    </div>
  );
}
