import Box from '@amzn/awsui-components-react/polaris/box';

import Badge from "@amzn/awsui-components-react/polaris/badge";
import Button from "@amzn/awsui-components-react/polaris/button";

import {TableProps} from '@amzn/awsui-components-react/polaris/table';
import {Pet} from '../../model/api_model';
import {ReactNode, useState} from 'react';
import {Link} from "@amzn/awsui-components-react";
import axios from "axios";
import {render} from "react-dom";

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Pet>[] = [
    {
        id: 'id',
        header: 'ID',
        cell: item => item.id,
        minWidth: '80px',
        sortingField: "id"
    },
    {
        id: 'ticketId',
        header: 'Ticket ID',
        cell: item => (<Link href={"https://t.corp.amazon.com/" + item.ticketId}>{item.ticketId}</Link>),
        minWidth: '80px',
        sortingField: "ticketId"
    },
    {
        id: 'resolverGroup',
        cell: item => (<Link href={"https://cti.amazon.com/group/" + item.resolverGroup}>{item.resolverGroup}</Link>),
        header: 'ResolverGroup',
        minWidth: '80px',
        sortingField: "resolverGroup"
    },
    {
        id: 'vipName',
        header: 'VIP Name',
        cell: item => (<Link href={item.vipLink}>{item.vipName}</Link>),
        minWidth: '120px',
        sortingField: "vipName"
    },
    {
        id: 'certZeroStatus',
        header: 'certBoundStatus',
        cell: item => {
            if (item.certZeroStatus === 'green') {
                return <Badge color="green">Completed</Badge>
            } else if (item.certZeroStatus === 'red') {
                return <Badge color="red">Cert not bound</Badge>
            } else {
                return <Badge color="blue">In Progress</Badge>
            }
        },
        minWidth: '180px',
        sortingField: "certZeroStatus"
    },
    {
        id: 'latest_cert_serialNumber',
        header: 'SerialNumber',
        cell: item => item.latest_cert_serialNumber,
        minWidth: '100px'
    },
    {
        id: 'envForVip',
        header: 'Environment mapped to VIP',
        cell: item => (<Link target='blank' href={"https://apollo.amazon.com/quick_deployment.html?environmentId=" + item.envId+"&stage="+item.stage+"&action=FlipActivate"}>{item.envForVip}</Link>),
        minWidth: '120px',
        sortingField: "envForVip"
    },
    {
        id: 'action',
        header: 'Action',
        cell: item => {
            if(item.certZeroStatus === 'yellow'){
                return <Button>Wait to complete</Button>;
            }
            else if (item.action === 'Resolve ticket') {
                let url = "https://2erlcggu5f.execute-api.us-west-2.amazonaws.com/v1/ticketid/"+item.ticketId;
                return <Button disabled={item.disabled} target="_blank" variant="primary" onClick={() =>
                    {
                    resolve(url);
                    } }>Resolve Ticket</Button>
            }
            else if (item.action === 'Resolved') {
                return <Button disabled={true}  variant="primary" >Resolved</Button>
            }
            else if (item.action === 'Bounce the Environment') {
                return <Button  href={"https://apollo.amazon.com/quick_deployment.html?environmentId="+item.envId+"&stage="+item.stage+"&action=FlipActivate"} target="_blank"
                               variant="primary">Activate fleet</Button>
            } else {
                return <Button>NA</Button>;
            }
        },
        minWidth: '200px',
        sortingField: "action"
    }
];

export function getMatchesCountText(count: number) {
    return count === 1 ? `1 match` : `${count} matches`;
}

export function resolve(url){
    let success_data = "Success: \"Ticket Resolved.\""
    return axios.get(url, {withCredentials:false}).then(function (response) {
        if(response.status === 200 && response.data === success_data){
            console.log(response.data)
            //Need to add one more UI component
            //document.getElementById("resolve").style.display="none";
            /*const app = document.getElementById("resolve");
            app.style.display="none"*/
        }
    })
}


interface EmptyStateProps {
    title: string;
    subtitle: string;
    action: ReactNode;
}

export function EmptyState({title, subtitle, action}: EmptyStateProps) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{bottom: 's'}} color="inherit">
                {subtitle}
            </Box>
            {action}
        </Box>
    );
}

export const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page'
};


/*export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [
 { value: 'table', label: 'Table' },
 { value: 'cards', label: 'Cards' }
]; */
