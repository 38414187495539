import {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useCollection} from '@amzn/awsui-collection-hooks';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import {COLUMN_DEFINITIONS, getMatchesCountText, EmptyState, paginationLabels} from './table-config';
import PetsApiFactory from '../../pets-api/PetsApiFactory';
import {Pet} from '../../model/api_model';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import {CollectionPreferences} from '@amzn/awsui-components-react';

export default function TicketTable() {
    const [allItems, setAllItems] = useState<Pet[]>();
    const [refreshTableFlag, setRefreshTableFlag] = useState(false);
    const [maxId, setMaxId] = useState(0);
    const history = useHistory();
    const {ticketId} = useParams<{ticketId:string}>();
    const createButton = (
        <Button variant="primary" iconName="add-plus" href={`#/form/create/${maxId}`}>
            Create
        </Button>
    );

    const tableData = [{
        "id": 1,
        "ticketId": "D43020643",
        "resolverGroup": "PersonIDService-Autocuts",
        "vipName": "personid-na-prod-ca.iad.amazon.com",
        "vipLink": "https://vip-management-iad.amazon.com/vipmgmt/StartWithDnsName.mhtml?vip=personid-na-prod-ca.iad.amazon.com&search-type=on",
        "certZeroStatus": "green",
        "latest_cert_serialNumber": "daa4ddc6c2048650683911fc51128861",
        "envForVip": "PersonIDService/NA/OneBox",
        "envId": "11895529",
        "stage": "Prod",
        "action": "Resolve ticket",
        "disabled": false
    }, {
        "id": 2,
        "ticketId": "V548292729",
        "resolverGroup": "AlexaSpeakerSpeechlet-Autocuts",
        "vipName": "asws-mlj-jp-pdx-pd-tcp.integ.amazon.com",
        "vipLink": "https://vip-management-corp.amazon.com/vipmgmt/StartWithDnsName.mhtml?vip=asws-mlj-jp-pdx-pd-tcp.integ.amazon.com&search-type=on",
        "certZeroStatus": "green",
        "latest_cert_serialNumber": "ea7c0da8f25d7bade2faf6c0887ad4b0",
        "envForVip": "AlexaSpeakerWorkflowsService/Integ/JP/PreDevo",
        "envId": "23092981",
        "stage": "Alpha",
        "action": "Resolve ticket",
        "disabled": false
    }, {
        "id": 3,
        "ticketId": "V554604258",
        "resolverGroup": "AlexaSpeakerSpeechlet-Autocuts",
        "vipName": "aicp-aq0-jp-pdx-d-tcp.integ.amazon.com",
        "vipLink": "https://vip-management-corp.amazon.com/vipmgmt/StartWithDnsName.mhtml?vip=aicp-aq0-jp-pdx-d-tcp.integ.amazon.com&search-type=on",
        "certZeroStatus": "red",
        "latest_cert_serialNumber": "a82b744017afb243b16349041fd67587",
        "envForVip": "AlexaIdentityContentProviderService/Integ/JP/Devo",
        "envId": "27069503",
        "stage": "Beta",
        "action": "Bounce the Environment",
        "disabled": false
    },
        {
            "id": 4,
            "ticketId": "V557739357",
            "resolverGroup": "Alexa-Photos-Autocuts",
            "vipName": "apps-p82-main-eu-pp-tcp.dub.amazon.com",
            "vipLink": "https://vip-management-dub.amazon.com/vipmgmt/StartWithDnsName.mhtml?vip=apps-p82-main-eu-pp-tcp.dub.amazon.com&search-type=on",
            "certZeroStatus": "yellow",
            "latest_cert_serialNumber": "3966d199962bf7a0ac1c7c1898ed6cca",
            "envForVip": "AlexaPersonalPhotosSpeechletService/Prod/Main/EU/PreProd",
            "envId": "18123009",
            "stage": "Gamma",
            "action": "Resolve ticket",
            "disabled": false
        },
        {
            "id": 5,
            "ticketId": "V554604256",
            "resolverGroup": "alexa-behaviors-autocuts",
            "vipName": "alexa-janus-demo.dub.amazon.com",
            "vipLink": "https://vip-management-dub.amazon.com/vipmgmt/StartWithDnsName.mhtml?vip=alexa-janus-demo.dub.amazon.com&search-type=on",
            "certZeroStatus": "red",
            "latest_cert_serialNumber": "47c18d4816e8c82d65ce9bbefa1c4e16",
            "envForVip": "AlexaJanusService/EU/IntegrationTests",
            "envId": "25333699",
            "stage": "Prod",
            "action": "Bounce the Environment",
            "disabled": false
        },
        {
            "id": 6,
            "ticketId": "V555057749",
            "resolverGroup": "MMContentService-Autocuts",
            "vipName": "alexa-mmhps-na-ca.integ.amazon.com",
            "vipLink": "https://vip-management-corp.amazon.com/vipmgmt/StartWithDnsName.mhtml?vip=alexa-mmhps-na-ca.integ.amazon.com&search-type=on",
            "certZeroStatus": "green",
            "latest_cert_serialNumber": "0b61abf05f948f1bc22cc33356f82e02",
            "envForVip": "AlexaMMHomePublishingService/NA",
            "envId": "18271219",
            "stage": "Beta",
            "action": "Resolve ticket",
            "disabled": false
        },
        {
            "id": 7,
            "ticketId": "V551565792",
            "resolverGroup": "MMContentService-Autocuts",
            "vipName": "mmhcs-s1-d-na.integ.amazon.com",
            "vipLink": "https://vip-management-corp.amazon.com/vipmgmt/StartWithDnsName.mhtml?vip=mmhcs-s1-d-na.integ.amazon.com&search-type=on",
            "certZeroStatus": "green",
            "latest_cert_serialNumber": "01e67c2e60e7ad6682dba43891922647",
            "envForVip": "AlexaMMHomeContentService/S1/NA",
            "envId": "17978117",
            "stage": "Beta",
            "action": "Resolve ticket",
            "disabled": false
        }]

const filteredTableData = tableData.filter(item=>item.ticketId===ticketId);
    useEffect(() => {
        (async () => {
            //setAllItems( (await PetsApi.listPets()).data );
            setAllItems(filteredTableData);
        })();
    }, [refreshTableFlag]);
    /*
        useEffect(() => {
         setMaxId( 1 + (items.length > 0 ? Math.max(...items.map(i=>i.id)) : 0 ) );
       }, [allItems]); */


    const {items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps} = useCollection(
        allItems || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No records"
                        subtitle="No records to display."
                        action={createButton}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: 10
            },
            sorting: {},
            selection: {}
        }
    );

    const {selectedItems} = collectionProps;
    return (
        <div>
        <Table
            {...collectionProps}
            loading={!allItems}
            loadingText="Loading instances"
            header={
                <Header
                    counter={
                        allItems &&
                        (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                    }
                    actions={
                        <SpaceBetween direction="horizontal" size="m">
                        </SpaceBetween>
                    }
                >
                    Redfort Cert details
                </Header>

            }

            columnDefinitions={COLUMN_DEFINITIONS}
            items={items}
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}/>}
            preferences={
                <CollectionPreferences
                    title="Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    preferences={{
                        pageSize: 10,
                        visibleContent: [
                            "ticketId",
                            "resolverGroup",
                            "vipName",
                            "certZeroStatus",
                            "latest_cert_serialNumber",
                            "action"
                        ]
                    }}
                    pageSizePreference={{
                        title: "Select page size",
                        options: [
                            {value: 10, label: "10 resources"},
                            {value: 20, label: "20 resources"}
                        ]
                    }}
                    visibleContentPreference={{
                        title: "Select visible content",
                        options: [
                            {
                                label: "Main distribution properties",
                                options: [
                                    {
                                        id: "ticketId",
                                        label: "Ticket ID",
                                        editable: false
                                    },
                                    {id: "resolverGroup", label: "Resolver Group"},
                                    {id: "vipName", label: "VIP name"},
                                    {
                                        id: "certZeroStatus",
                                        label: "Cert Status"
                                    },
                                    {
                                        id: "latest_cert_serialNumber",
                                        label: "Serialnumber"
                                    },
                                    {
                                        id: "action",
                                        label: "Action"
                                    }
                                ]
                            }
                        ]
                    }}
                />
            }
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount!)}
                    filteringAriaLabel="Filter records"
                />
            }
        />
        </div>

    );
}