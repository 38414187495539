import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Link from '@amzn/awsui-components-react/polaris/link';
import * as tokens from '@amzn/awsui-design-tokens';

export default function CommonHeader() {
  return (
    <div style={{ background: tokens.colorBackgroundHomeHeader }}>
      <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
        <Grid
          gridDefinition={[
            { offset: { l: 2, xxs: 1 }, colspan: { l: 7, xxs: 9 } },
            { offset: { l: 2, xxs: 1 }, colspan: { l: 7, xxs: 9 } }
          ]}
        >
          <div className="custom-home-text-inverted">
          <Link href='#' >
          
            <span className="header-text"> 
            <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
            <img alt="Logo" className='img' src="logo.png" width="50" height="50" />Alexa Redfort Helper
            </Box>
            </span>
            </Link>
            <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
              OneStop to action on Redfort certs
            </Box>
            <Box variant="p" fontWeight="light">
              <span className="custom-home-text-secondary">
                Do you have any question for Redfort Certificates? &nbsp;
                <Link href="https://w.amazon.com/bin/view/Redfort/Certificate/" target="_blank">
                  Refer this!
                </Link>
              </span>
            </Box>
          </div>
        </Grid>
      </Box>
    </div>
  );
}
