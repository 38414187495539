import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Container from '@amzn/awsui-components-react/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import DetailsTable from '../table/Table';
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import { useHistory } from 'react-router-dom';


// The content in the main content area of the App layout
export default function HomepageContent() {
  const history = useHistory();
  return (
    <>
      <Box padding={{ top: 'xxl', horizontal: 's', bottom: 'l' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 10, l: 9, s: 10, xxs: 10 }, offset: { l: 2, xxs: 1 } }
          ]}
        >
          <SpaceBetween size="l">
            <div>
              <Header variant="h1" headingTagOverride="h2">
              <strong>AlexaRedfortHelper</strong><span className="btn-span float-right"> <Button className="btn"
               onClick={() => history.push("/form/create/1")}
               variant="primary">Get Started</Button></span>
              </Header>
              <Container>
              <p>
              Have you ever received Redfort certificate tickets during your on-call shift? Have you ever struggled to find the necessary details of the certificate expired? What is the current status of binding? What should be the next steps to be followed?
<br></br>
               <b>AlexaRedfortHelper</b> is a solution to automate Redfort Certificate binding aiming to reduce the effort spent and resolution time of Redfort Certs tickets.
            </p>
              </Container>
            </div>
          </SpaceBetween>
        </Grid>
      </Box>
      <Box padding={{ top: 'xxl', horizontal: 's', bottom: 'l' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 10, l: 9, s: 10, xxs: 10 }, offset: { l: 2, xxs: 1 } }
          ]}
        >
          <SpaceBetween size="l">
            <div>
              <Header variant="h1" headingTagOverride="h2">
              <strong>Benefits and features</strong>
              </Header>
              <Container>
              <div className="awsui-util-container">
                <div data-awsui-column-layout-root={true}>
                <ColumnLayout columns={2}>
                  <div>
                  <h3 className="awsui-util-pt-n">One Stop</h3>
                  <p>
                    View consolidated list of RedFort certs mapped to the Resolver groups you are part of. SMEs don't need to book mark the Redfort certs since the necessary details are available in the
                    Alexa Redfort Helper.
                  </p>
                  </div>
                  <div>
                  <h3 className="awsui-util-pt-n">Skip Onboarding</h3>
                  <p>
                      Hey, Do I have to onboard Resolver groups for which I want to see the Redfort Certs?
                      We have you covered! Check Redfort cert status for the Resolver Group without any onboarding process.

                  </p>
                  </div>
                  <div>
                  <h3 className="awsui-util-pt-n">Cert Health Status</h3>
                  <p>
                    What if you cert is yellow & emitting daysToExpiry metric for 5 min period? Cram the ticket ID & check it again tomorrow if  daysToExpiry metric changes to a period of 1 day.
                    Don't wait & bookmark the ticket it, We have Redfort Helper for you! Use Redfort Helper to track the current status of Certs and its expiry date.

                  </p>
                  </div>
                  <div>
                  <h3 className="awsui-util-pt-n">Actions based on Cert Status</h3>
                  <p>
                    One-click to take respective actions on binding certs: whether it be bouncing the fleet or resolving the ticket or getting the relevant details. We have you covered. Have all the data collated at a single place without hoping over
                    Apollo, Redfort, VIP Management portal & t.corp to resolve the ticket.
                  </p>
                  </div>
                  </ColumnLayout>
                </div>
            </div>
            
              </Container>
              
            </div>
            
          </SpaceBetween>
        </Grid>
      </Box>
    </>
  );
}
